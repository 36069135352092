import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const schema = yup.object().shape({
  firstName: yup.string().required('Bitte geben Sie Ihren Vornamen ein.'),
  lastName: yup.string().required('Bitte geben Sie Ihren Nachnamen ein.'),
  email: yup.string().email('Bitte geben Sie eine gültige E-Mail-Adresse ein.').required('Bitte geben Sie Ihre E-Mail-Adresse ein.'),
});

const UserInfoModal = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Validierung der Benutzereingaben
      await schema.validate({ firstName, lastName, email }, { abortEarly: false });

      // Übergibt die Benutzerdaten an die übergeordnete Komponente, ohne den API-Aufruf
      onSubmit({ firstName, lastName, email });

    } catch (error) {
      if (error.name === 'ValidationError') {
        error.inner.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        console.error('Fehler beim Übermitteln der Daten:', error);
        toast.error('Es gab ein Problem. Bitte versuchen Sie es erneut.');
      }
    }
  };

  return (
    <div className="user-info-modal">
      <h2>Wie können wir helfen?</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Vorname"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Nachname"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="E-Mail"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Chat starten
        </Button>
      </form>
      <p className="privacy-notice">
        Indem Sie fortfahren, stimmen Sie unseren <a href="/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutzbestimmungen</a> zu.
      </p>
    </div>
  );
};

export default UserInfoModal;
