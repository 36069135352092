import React, { useState, useEffect } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import UserInfoModal from './UserInfoModal';
import FeedbackForm from './FeedbackForm';
import { toast } from 'react-toastify';
import api from '../axios';
import ReactGA from 'react-ga4';

const ChatWindow = ({ onEndChat }) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [chatInfo, setChatInfo] = useState(null); // To store chat data
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  useEffect(() => {
    // Nur ausführen, wenn chatInfo gesetzt ist und eine Welcome Message vorhanden ist
    if (chatInfo && chatInfo.welcomeMessage && messages.length === 0) {
      const welcomeMessage = {
        sender: 'bot',
        text: chatInfo.welcomeMessage,
        timestamp: new Date(),
      };
      setMessages([welcomeMessage]);
    }
  }, [chatInfo]); // Überwache nur Änderungen an chatInfo

  const handleUserInfoSubmit = async (info) => {
    try {
      const response = await api.post('/api/Chat/Start', {
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
      });
  
      const { chat, welcomeMessage } = response.data;
  
      // Set the user and chat information
      setUserInfo(info);
      setChatInfo({ ...chat, welcomeMessage });
  
      // Track event
      ReactGA.event({
        category: 'Chat',
        action: 'chat_started',
        label: `Chat started with ${info.firstName}`
      });
  
    } catch (error) {
      console.error('Fehler beim Starten des Chats:', error);
      toast.error('Es gab ein Problem beim Starten des Chats. Bitte versuchen Sie es erneut.');
    }
  };
  
  

  const handleSendMessage = async (messageText) => {
    if (!chatInfo || !chatInfo.id) {
      console.error('chatId ist nicht verfügbar.');
      toast.error('Es gab ein Problem mit der Chat-Verbindung. Bitte starten Sie den Chat neu.');
      return;
    }
  
    const userMessage = {
      sender: 'user',
      text: messageText,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
  
    // Event tracking: User sent a message
    ReactGA.event({
      category: 'Chat',
      action: 'message_sent',
      label: 'User sent a message'
    });
  
    setIsTyping(true);
  
    try {
      const response = await api.post(`/api/Chat/${chatInfo.id}/SendMessage`, {
        userMessage: messageText,
      });
  
      const aiMessageText = response.data.content;
  
      const aiMessage = {
        sender: 'bot',
        text: aiMessageText,
        timestamp: new Date(),
      };
  
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        toast.error('Zu viele Anfragen. Bitte warten Sie einen Moment, bevor Sie eine weitere Nachricht senden.');
        ReactGA.event({
          category: 'Error',
          action: 'rate_limit_reached',
          label: 'User hit rate limit'
        });
      } else {
        console.error('Fehler beim Senden der Nachricht:', error);
        toast.error('Es gab ein Problem beim Senden Ihrer Nachricht. Bitte versuchen Sie es erneut.');
      }
    } finally {
      setIsTyping(false);
    }
  };
  

  const handleEndChat = async () => {
    if (chatInfo && chatInfo.id) {
      try {
        if (showFeedbackForm) {
          onEndChat();
          return;
        }
  
        await api.post(`/api/Chat/${chatInfo.id}/End`);
        setShowFeedbackForm(true);
  
        // Event tracking: Chat ended
        ReactGA.event({
          category: 'Chat',
          action: 'chat_ended',
          label: 'Chat ended by user'
        });
  
      } catch (error) {
        console.error('Fehler beim Beenden des Chats:', error);
        toast.error('Es gab ein Problem beim Beenden des Chats. Bitte versuchen Sie es erneut.');
      }
    } else {
      onEndChat();
    }
  };
  

  const handleFeedbackSubmitted = () => {
    onEndChat();
  };

  return (
    <div className="chat-window">
      <button className="end-chat-button" onClick={handleEndChat}>
        ✕
      </button>
      {!userInfo ? (
        <UserInfoModal onSubmit={handleUserInfoSubmit} />
      ) : showFeedbackForm ? (
        <FeedbackForm chatId={chatInfo.id} onFeedbackSubmitted={handleFeedbackSubmitted} />
      ) : (
        <>
          <MessageList messages={messages} isTyping={isTyping} />
          <MessageInput onSendMessage={handleSendMessage} />
        </>
      )}
    </div>
  );
};

export default ChatWindow;
