import React, { useEffect, useRef } from 'react';
import { ListItem, ListItemText, Avatar, Typography, Box } from '@mui/material';

const MessageList = ({ messages, isTyping }) => {
  const messageListRef = useRef(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages, isTyping]);

  return (
    <div className="message-list" ref={messageListRef}>
      {messages.map((msg, index) => (
        <div key={index} className={`message-container ${msg.sender === 'user' ? 'message-right' : 'message-left'}`}>
          <ListItem 
            alignItems="flex-center" 
            sx={{
              border: "1px solid rgb(0 0 0 / 4%)", 
              borderRadius: "5px", 
              position: "relative", 
              paddingRight: '40px' // Platz für den Zeitstempel schaffen
            }}
          >
            <Avatar
              sx={{ marginRight: "15px" }}
              alt={msg.sender === 'user' ? 'Benutzer' : 'KI'}
              src={
                msg.sender === 'user'
                  ? 'https://cdn3.iconfinder.com/data/icons/human-icons-for-interfaces/100/PROFILE-512.png'
                  : 'https://cdn-icons-png.flaticon.com/512/6461/6461777.png'
              }
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {/* Benutzername / Sender */}
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                {msg.sender === 'user' ? 'Benutzer' : 'KI'}
              </Typography>
              {/* Nachricht */}
              <ListItemText
                primary={msg.text}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: '5px',
                right: '10px',
                fontSize: '0.8rem',
                color: 'rgba(0, 0, 0, 0.6)', // Leichtes Grau für den Text
              }}
            >
              {msg.timestamp.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' })}
            </Box>
          </ListItem>
        </div>
      ))}
      {isTyping && (
        <div className="typing-indicator">
          <Avatar 
            alt="KI"
            src="https://cdn-icons-png.flaticon.com/512/6461/6461777.png"
          />
          <div className="typing-animation">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageList;
