import React, { useState, useEffect } from 'react';
import ChatWindow from './components/ChatWindow';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import api from './axios';
import ReactGA from 'react-ga4';

function App() {
  useEffect(() => {
    // Google Analytics initialisieren
    ReactGA.initialize('G-FSTBDMGGR0', {
      debug_mode: false,
      gtagOptions: {
        cookie_domain: 'none',
        cookie_flags: 'SameSite=None; Secure',
      },
    });

    // Page View Event senden
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const [chatOpen, setChatOpen] = useState(false);
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwtToken'));
  const [apiKey, setApiKey] = useState(null);
  const [loadingApiKey, setLoadingApiKey] = useState(true);

  // Empfängt den API-Key vom iframe (dynamisch für jeden Kunden)
  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.data.apiKey) {
        setApiKey(event.data.apiKey);
        setLoadingApiKey(false);
      }
    };

    window.addEventListener('message', receiveMessage);

    // Timeout für den API-Key-Check
    const timeoutId = setTimeout(() => {
      setLoadingApiKey(false);
    }, 2000);

    return () => {
      window.removeEventListener('message', receiveMessage);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const fetchJwtToken = async () => {
      if (!apiKey) {
        return;
      }
      try {
        const response = await api.post('/api/Auth/trade-key', { apiKey });
        if (response.status === 200) {
          const data = response.data;
          setJwtToken(data.token);
          localStorage.setItem('jwtToken', data.token);
        } else {
          localStorage.removeItem('jwtToken');
          const errorText = response.data.message || 'Unbekannter Fehler';
          toast.error(`Token-Fehler: ${errorText}`);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen des JWT-Tokens:', error);
        toast.error('Fehler beim Abrufen des Tokens');
      }
    };

    if (chatOpen && !jwtToken && apiKey) {
      localStorage.removeItem('jwtToken');
      fetchJwtToken();
    }
  }, [chatOpen, apiKey, jwtToken]);

  const handleChatIconClick = () => {
    setChatOpen(true);

    // Event Tracking mit react-ga4
    ReactGA.event({
      category: 'Chat',
      action: 'chat_opened',
      label: 'Chat window opened',
    });

    // Nachricht an das iframe senden, um den Chat zu öffnen
    const iframe = document.getElementById('chat-widget');
    iframe?.contentWindow?.postMessage({ openChat: true }, '*');
  };

  const handleEndChat = () => {
    ReactGA.event({
      category: 'Chat',
      action: 'chat_closed',
      label: 'Chat window closed',
    });

    setChatOpen(false);

    // Nachricht an das iframe senden, um den Chat zu schließen
    const iframe = document.getElementById('chat-widget');
    iframe?.contentWindow?.postMessage({ closeChat: true }, '*');
  };

  // Neuer useEffect, um den chatOpen-Status an die Elternseite zu senden
  useEffect(() => {
    // Sende den chatOpen-Status an die Elternseite
    window.parent.postMessage({ chatOpen }, '*');
  }, [chatOpen]);

  return (
    <div className="App">
      <ToastContainer position="bottom-right" autoClose={5000} />

      {!loadingApiKey && apiKey ? (
        <>
          {!chatOpen && (
            <button className="chat-icon-button" onClick={handleChatIconClick}>
              <img
                src="https://static-00.iconduck.com/assets.00/bubble-chat-icon-2048x2046-oftt9aw0.png"
                alt="Chat starten"
              />
            </button>
          )}

          {chatOpen && jwtToken && <ChatWindow onEndChat={handleEndChat} />}
        </>
      ) : !loadingApiKey && !apiKey ? (
        <p>Kein API-Key gefunden. Das Chat-Fenster wird nicht angezeigt.</p>
      ) : null}
    </div>
  );
}

export default App;
