import React, { useState } from 'react';
import { TextField, Button, Rating } from '@mui/material';
import { toast } from 'react-toastify';
import api from '../axios';
import ReactGA from 'react-ga4';


const FeedbackForm = ({ chatId, onFeedbackSubmitted }) => {
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (rating === 0) {
      toast.error('Bitte geben Sie eine Bewertung ab.');
      return;
    }
  
    try {
      await api.post(`/api/Chat/${chatId}/Feedback`, {
        rating,
        comments,
      });
  
      // Google Analytics Event für gesendetes Feedback
      ReactGA.event({
        category: 'Feedback',
        action: 'feedback_submitted',
        label: 'User submitted feedback',
        value: rating
      });
  
      toast.success('Vielen Dank für Ihr Feedback!');
    } catch (error) {
      console.error('Fehler beim Senden des Feedbacks:', error);
      toast.error('Es gab ein Problem beim Senden Ihres Feedbacks. Ihr Feedback wurde nicht gespeichert.');
    } finally {
      onFeedbackSubmitted();
    }
  };

  const handleSkipFeedback = () => {
    onFeedbackSubmitted();

    // Google Analytics Event für übersprungenes Feedback
    ReactGA.event({
      category: 'Feedback',
      action: 'feedback_skipped',
      label: 'User skipped feedback'
    });
  };

  return (
    <div className="feedback-form">
      <h2>Vielen Dank für Ihren Besuch!</h2>
      <p>Wir würden uns über Ihr Feedback freuen.</p>
      <form onSubmit={handleSubmit}>
        <div className="feedback-rating">
          <span>Bewertung:</span>
          <Rating
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </div>
        <TextField
          label="Kommentare"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Feedback senden
        </Button>
        <Button
          variant="text"
          color="secondary"
          fullWidth
          style={{ marginTop: '10px' }}
          onClick={handleSkipFeedback}
        >
          Feedback überspringen
        </Button>
      </form>
      <p className="error-report">
        Probleme? <a href="mailto:support@blub.ai">Kontaktieren Sie uns</a>.
      </p>
    </div>
  );
};

export default FeedbackForm;
